<script lang="ts" setup>
	import type { ICloudflareVideo } from "~/server/models/mixins/cloudflareVideo";

	const props = withDefaults(
		defineProps<{
			cloudflare: ICloudflareVideo;
			autoplay?: boolean;
		}>(),
		{
			autoplay: true,
		}
	);

	const config = useRuntimeConfig();

	const hls = computed(() => {
		return `https://${config.public.cloudflare.streamUrl}/${props.cloudflare.id}/manifest/video.m3u8`;
	});

	const poster = computed(() => {
		return `https://${config.public.cloudflare.streamUrl}/${props.cloudflare.id}/thumbnails/thumbnail.jpg`;
	});

	const loaded = ref(false);

	function loadeddata() {
		loaded.value = true;
	}
</script>

<template>
	<HLSVideo :class="[{ loaded: loaded }]" @loadeddata="loadeddata" class="cloudflare-player" :src="hls" :poster="poster" :muted="true" :autoplay="autoplay" :loop="true" />
</template>

<style scoped lang="scss">
	.cloudflare-player {
		opacity: 0;
		transition: all 0.5s ease-out;
	}
	.cloudflare-player.loaded {
		opacity: 1;
	}
</style>
