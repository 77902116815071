<script lang="ts" setup>
	import type { IR2Video } from "~/server/models/mixins/r2Video";

	const props = withDefaults(
		defineProps<{
			r2: IR2Video;
			autoplay?: boolean;
		}>(),
		{
			autoplay: true,
		}
	);

	const config = useRuntimeConfig();

	const src = computed(() => {
		return `${config.public.cloudflare.r2Url}/${props.r2.filename}`;
	});

	/* const poster = computed(() => {
		return `${config.public.cloudflare.r2Url}/${props.r2.thumbnail}`;
	}); */

	const loaded = ref(false);

	function loadeddata() {
		loaded.value = true;
	}
</script>

<template>
	<HLSVideo :hls="false" :class="[{ loaded: loaded }]" @loadeddata="loadeddata" class="r2-player" :src="src" :muted="true" :autoplay="autoplay" :loop="true" />
</template>

<style scoped lang="scss">
	.r2-player {
		opacity: 0;
		transition: all 0.5s ease-out;
	}
	.r2-player.loaded {
		opacity: 1;
	}
</style>
