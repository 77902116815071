<script lang="ts" setup>
	import type { ICloudflareVideo } from "~/server/models/mixins/cloudflareVideo";

	const props = defineProps<{
		cloudflare: ICloudflareVideo;
	}>();

	const config = useRuntimeConfig();

	const src = computed(() => {
		return `https://${config.public.cloudflare.streamUrl}/${props.cloudflare.id}/thumbnails/thumbnail.jpg`;
	});

	const complete = ref(false);

	const img = ref<HTMLImageElement | null>(null);

	onMounted(() => {
		const element = img.value as HTMLImageElement;

		nextTick(() => {
			if (!element || !element.complete) {
				element.addEventListener("load", () => {
					complete.value = true;
				});
			} else {
				complete.value = true;
			}
		});
	});
</script>

<template>
	<img ref="img" class="cloudflare-poster" :class="[{ complete }]" :src="src" />
</template>

<style scoped lang="scss">
	.cloudflare-poster {
		object-fit: cover;
		object-position: center center;
		opacity: 0;
		transition: all 0.5s ease-out;
	}

	.cloudflare-poster {
		opacity: 1;
	}
</style>
