<script lang="ts" setup>
	import type { IR2Video } from "~/server/models/mixins/r2Video";

	const props = defineProps<{
		r2: IR2Video;
	}>();

	const config = useRuntimeConfig();

	const src = computed(() => {
		return `${config.public.cloudflare.r2Url}/${props.r2.thumbnail}`;
	});

	const complete = ref(false);

	const img = ref<HTMLImageElement | null>(null);

	onMounted(() => {
		const element = img.value as HTMLImageElement;

		nextTick(() => {
			if (!element || !element.complete) {
				element.addEventListener("load", () => {
					complete.value = true;
				});
			} else {
				complete.value = true;
			}
		});
	});
</script>

<template>
	<img ref="img" class="r2-poster" :class="[{ complete }]" :src="src" />
</template>

<style scoped lang="scss">
	.r2-poster {
		object-fit: cover;
		object-position: center center;
		opacity: 0;
		transition: all 0.5s ease-out;
	}

	.r2-poster {
		opacity: 1;
	}
</style>
