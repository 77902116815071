<script lang="ts" setup>
	import type { IMuxVideo } from "~/server/models/mixins/muxVideo";

	const props = defineProps<{
		mux: IMuxVideo;
	}>();

	const src = computed(() => {
		const url = new URL(`https://image.mux.com/${props.mux.playback_id}/thumbnail.jpg`);
		return url.toString();
	});

	const complete = ref(false);

	const img = ref<HTMLImageElement | null>(null);

	onMounted(() => {
		const element = img.value as HTMLImageElement;

		nextTick(() => {
			if (!element || !element.complete) {
				element.addEventListener("load", () => {
					complete.value = true;
				});
			} else {
				complete.value = true;
			}
		});
	});
</script>

<template>
	<img ref="img" class="mux-poster" :class="[{ complete }]" :src="src" />
</template>

<style scoped lang="scss">
	.mux-poster {
		object-fit: cover;
		object-position: center center;
		opacity: 0;
		transition: all 0.5s ease-out;
	}

	.mux-poster {
		opacity: 1;
	}
</style>
